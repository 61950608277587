<!-- src/components/MySvelteSPA.svelte -->
<script lang="ts">
    import AuthInit from "@svelte/init/auth.svelte";
    import I18nInit from "@svelte/init/i18n.svelte";

    import Router from "svelte-spa-router";

    import { COMPONENTS_ROUTES } from "@svelte/pages/components/routes";
    import { ROUTES } from "@svelte/pages/routes";

    import { isLoading } from "svelte-i18n";

    const routes = {
        ...COMPONENTS_ROUTES,
        ...ROUTES,
    };
</script>

<I18nInit />
<AuthInit />

{#if $isLoading}
    Please wait...
{:else}
    <Router {routes} />
{/if}
