<script lang="ts">
    import Button from "@svelte/design/actions/button.svelte";
    import Dropdown from "@svelte/design/actions/dropdown.svelte";
    import NavBar from "@svelte/design/navigation/navbar.svelte";

    import LocaleSwitcher from "@svelte/features/common/locale-switcher.svelte";
    import ThemeSwitcher from "@svelte/features/common/theme-switcher.svelte";

    import { COMPONENT_TYPES } from "../../design/settings.const";
    import ActionsComponent from "./actions.component.svelte";
    import DisplayComponent from "./display.component.svelte";
    import InputsComponent from "./inputs.component.svelte";
    import NavigationComponent from "./navigation.component.svelte";

    const COMPONENTS = {
        DISPLAY: "Display",
        ACTIONS: "Actions",
        NAVIGATION: "Navigation",
        INPUTS: "Inputs",
    };

    let currentComponent = COMPONENTS.DISPLAY;
    let isDComponentDropdownOpen = false;

    function changeComponent(component) {
        currentComponent = component;
        isDComponentDropdownOpen = false;
    }
</script>

<NavBar bordered={true} class="my-4">
    <div slot="navbar-start">
        <Dropdown bind:isOpen={isDComponentDropdownOpen}>
            <span slot="dropdown-summary">Component: <strong>{currentComponent}</strong></span>
            {#each Object.values(COMPONENTS) as component}
                <li>
                    <Button type={COMPONENT_TYPES.GHOST} on:click={() => changeComponent(component)} bordered={component === currentComponent} shadow={false}
                        >{component}</Button>
                </li>
            {/each}
        </Dropdown>
    </div>
    <div slot="navbar-end">
        <ThemeSwitcher />
        <LocaleSwitcher />
    </div>
</NavBar>

<div class="flex flex-col gap-y-4 my-20">
    {#if currentComponent === COMPONENTS.DISPLAY}
        <DisplayComponent />
    {:else if currentComponent === COMPONENTS.ACTIONS}
        <ActionsComponent />
    {:else if currentComponent === COMPONENTS.NAVIGATION}
        <NavigationComponent />
    {:else if currentComponent === COMPONENTS.INPUTS}
        <InputsComponent />
    {:else}
        <p>Select component to be displayed</p>
    {/if}
</div>
