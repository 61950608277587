<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import { DEFAULT_BORDERED, DEFAULT_COMPONENT_SIZE, DEFAULT_COMPONENT_TYPE, DEFAULT_SHADOW } from "../settings.const";
    import { DEFAULT_BUTTON_STYLE } from "./button.const";

    export let type = DEFAULT_COMPONENT_TYPE;
    export let size = DEFAULT_COMPONENT_SIZE;
    export let style = DEFAULT_BUTTON_STYLE;
    export let outline = false;
    export let bordered = DEFAULT_BORDERED;
    export let disabled = false;
    export let shadow = DEFAULT_SHADOW;
    export let active = false;

    const dispatch = createEventDispatcher();

    function dispatchClick() {
        if (disabled) return;

        dispatch("click");
    }
</script>

<button
    class="
        btn
        {`btn-${type}`}
        {`btn-${size}`}
        {`btn-${style}`}
        {disabled ? 'btn-disabled' : ''}
        {active ? 'btn-active' : ''}
        {outline ? 'btn-outline' : ''}
        {bordered ? 'btn-bordered' : ''}
        {shadow ? 'shadow' : ''}
        {$$restProps.class || ''}"
    on:click={() => dispatchClick()}
    {disabled}>
    <slot name="prefix" />
    <slot />
    <slot name="suffix" />
</button>

<style>
    .btn-bordered {
        border-color: currentColor;
    }

    .btn-bordered:hover {
        border-color: transparent;
    }
</style>
