<script lang="ts">
    import { DEFAULT_BORDERED, DEFAULT_COMPONENT_TYPE, DEFAULT_SHADOW } from "../settings.const";
    import { CARD_STYLES } from "./card.const";

    export let style = CARD_STYLES.NORMAL;
    export let type = DEFAULT_COMPONENT_TYPE;
    export let bordered = DEFAULT_BORDERED;
    export let shadow = DEFAULT_SHADOW;
    export let imageFull = false;
    export let disabled = false;
</script>

<div
    class="
        rounded-box
        card
        card-{style}
        card-{type}
        {disabled ? 'card-disabled' : ''}
        {bordered ? 'card-bordered' : ''}
        {shadow ? 'shadow' : ''}
        {imageFull ? 'image-full' : ''}">
    <slot name="image-before" />
    <div class="card-body gap-4">
        <h2 class="card-title font-normal">
            <slot name="card-title" />
        </h2>
        <slot name="card-content" />
        <div class="card-actions justify-center">
            <slot name="card-actions" />
        </div>
    </div>
    <slot name="image-after" />
</div>

<style>
    .card-none {
        background-color: oklch(var(--b3));
        color: oklch(var(--bc));
    }

    .card-primary {
        background-color: oklch(var(--p));
        color: oklch(var(--pc));
    }

    .card-secondary {
        background-color: oklch(var(--s));
        color: oklch(var(--sc));
    }

    .card-neutral {
        background-color: oklch(var(--n));
        color: oklch(var(--nc));
    }

    .card-accent {
        background-color: oklch(var(--a));
        color: oklch(var(--ac));
    }

    .card-success {
        background-color: oklch(var(--su));
        color: oklch(var(--suc));
    }

    .card-info {
        background-color: oklch(var(--in));
        color: oklch(var(--inc));
    }

    .card-warning {
        background-color: oklch(var(--wa));
        color: oklch(var(--wac));
    }

    .card-error {
        background-color: oklch(var(--er));
        color: oklch(var(--erc));
    }

    .card-disabled {
        background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
        border-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-border-opacity)));
        color: var(--fallback-bc, oklch(var(--bc) / 0.4));
    }
</style>
