// store.js
import { writable } from "svelte/store";

// A function to read from localStorage
function getLocalStorage (key, defaultValue: unknown = undefined, isJson = false) {
    const storedValue = localStorage.getItem(key);
    if (storedValue !== null) {
        return isJson ? JSON.parse(storedValue) : storedValue;
    }
    return defaultValue;
}

// A function to create a writable store that syncs with localStorage
export function localStorageWritable (key, defaultValue: unknown = undefined, isJson = false) {
    const store = writable(getLocalStorage(key, defaultValue, isJson));

    store.subscribe(value => {
        localStorage.setItem(key, isJson ? JSON.stringify(value) : value);
    });

    return store;
}
