import { writable } from "svelte/store";

let inProgress = 0;

export const isLoading = writable(false);

export function loading() {
    inProgress++;
    if (inProgress > 0) {
        isLoading.set(true);
    }
}

export function loaded() {
    inProgress--;
    if (inProgress <= 0) {
        isLoading.set(false);
    }
}
