<script lang="ts">
    import { getLocaleFromNavigator, init, register } from "svelte-i18n";

    register("en", () => import("@locales/en.json"));
    register("pl", () => import("@locales/pl.json"));

    init({
        fallbackLocale: "en",
        initialLocale: getLocaleFromNavigator(),
    });
</script>
