import { COMPONENT_SIZES } from "../settings.const";

export function labelSize(size) {
    if (size === COMPONENT_SIZES.EXTRA_SMALL) {
        return "2xs";
    }

    if (size === COMPONENT_SIZES.SMALL) {
        return COMPONENT_SIZES.EXTRA_SMALL;
    }

    if (size === COMPONENT_SIZES.MEDIUM) {
        return COMPONENT_SIZES.SMALL;
    }

    if (size === COMPONENT_SIZES.LARGE) {
        return COMPONENT_SIZES.MEDIUM;
    }

    return size;
}
