<script lang="ts">
    import { DEFAULT_THEME } from "@constants/theme";
    import Icon from "@svelte/design/display/icon.svelte";
    import { currentTheme, setTheme, toggleTheme } from "@svelte/store/common/theme";
    import Cookies from "js-cookie";
    import { onMount } from "svelte";
    import Swap from "../../design/actions/swap.svelte";

    let isDefaultTheme = false;

    function setInDocument(theme) {
        document.documentElement.setAttribute("data-theme", theme);
    }

    function getFromDocument() {
        return document.documentElement.getAttribute("data-theme");
    }

    function setInBody(newTheme, oldTheme) {
        newTheme && document.body.classList.add("data", newTheme);
        oldTheme && document.body.classList.remove("data", oldTheme);
    }

    function setInCookie(theme) {
        Cookies.set("theme", theme);
    }

    function getFromCookie() {
        return Cookies.get("theme");
    }

    function onThemeToggled() {
        const oldTheme = $currentTheme;
        const theme = toggleTheme();
        setInDocument(theme);
        setInCookie(theme);
        setInBody(theme, oldTheme);
        isDefaultTheme = theme === DEFAULT_THEME;
    }

    onMount(() => {
        const theme = getFromDocument() || getFromCookie() || $currentTheme;
        setTheme(theme);
        setInDocument(theme);
        setInBody(theme, undefined);
        isDefaultTheme = theme === DEFAULT_THEME;
    });
</script>

<Swap on:change={onThemeToggled} checked={isDefaultTheme} bordered={false} shadow={false}>
    <Icon slot="swap-off" class="swap-off">dark_mode</Icon>
    <Icon slot="swap-on" class="swap-on">light_mode</Icon>
</Swap>
