<script>
    import { BUTTON_STYLES } from "@svelte/design/actions/button.const";
    import Button from "@svelte/design/actions/button.svelte";
    import Dropdown from "@svelte/design/actions/dropdown.svelte";
    import Toggle from "@svelte/design/inputs/toggle.svelte";
    import { COMPONENT_SIZES, COMPONENT_TYPES, DEFAULT_BORDERED, DEFAULT_SHADOW } from "@svelte/design/settings.const";

    let buttonOutline = false;
    let buttonBordered = DEFAULT_BORDERED;
    let buttonShadow = DEFAULT_SHADOW;
    let buttonDisabled = false;
    let buttonStyle = BUTTON_STYLES.DEFAULT;
    let buttonSize = COMPONENT_SIZES.MEDIUM;
    let buttonType = COMPONENT_TYPES.PRIMARY;
    let buttonAllTypes = false;
    let isButtonTypeOpen = false;
    let isButtonSizeOpen = false;
    let isButtonStyleOpen = false;

    function handleButtonTypeChange(type) {
        buttonType = type;
        isButtonTypeOpen = false;
    }

    function handleButtonSizeChange(size) {
        buttonSize = size;
        isButtonSizeOpen = false;
    }

    function handleButtonStyleChange(style) {
        buttonStyle = style;
        isButtonStyleOpen = false;
    }
</script>

<div class="space-y-4">
    <h3>Button</h3>

    <div class="flex flex-col">
        <Toggle on:click={() => (buttonOutline = !buttonOutline)} checked={buttonOutline}>Outline</Toggle>
        <Toggle on:click={() => (buttonBordered = !buttonBordered)} checked={buttonBordered}>Bordered</Toggle>
        <Toggle on:click={() => (buttonShadow = !buttonShadow)} checked={buttonShadow}>Shadow</Toggle>
        <Toggle on:click={() => (buttonDisabled = !buttonDisabled)} checked={buttonDisabled}>Disabled</Toggle>
        <Toggle on:click={() => (buttonAllTypes = !buttonAllTypes)} checked={buttonAllTypes}>All Types</Toggle>

        <Dropdown bind:isOpen={isButtonTypeOpen} disabled={buttonAllTypes}>
            <span slot="dropdown-summary">Selected Type: <strong>{buttonType}</strong></span>
            {#each Object.values(COMPONENT_TYPES) as type}
                <li>
                    <Button bordered={false} shadow={false} on:click={() => handleButtonTypeChange(type)} {type}>{type}</Button>
                </li>
            {/each}
        </Dropdown>

        <Dropdown bind:isOpen={isButtonSizeOpen}>
            <span slot="dropdown-summary">Selected Size: <strong>{buttonSize}</strong></span>
            {#each Object.values(COMPONENT_SIZES) as size}
                <li>
                    <Button bordered={false} shadow={false} on:click={() => handleButtonSizeChange(size)} type={COMPONENT_TYPES.GHOST}>{size}</Button>
                </li>
            {/each}
        </Dropdown>

        <Dropdown bind:isOpen={isButtonStyleOpen}>
            <span slot="dropdown-summary">Selected Style: <strong>{buttonStyle}</strong></span>
            {#each Object.values(BUTTON_STYLES) as style}
                <li>
                    <Button bordered={false} shadow={false} on:click={() => handleButtonStyleChange(buttonStyle)} type={COMPONENT_TYPES.GHOST}>{style}</Button>
                </li>
            {/each}
        </Dropdown>
    </div>

    <div class="flex flex-wrap gap-4">
        {#each buttonAllTypes ? Object.values(COMPONENT_TYPES) : [buttonType] as type}
            <Button
                {type}
                size={buttonSize}
                style={buttonStyle}
                outline={buttonOutline}
                bordered={buttonBordered}
                shadow={buttonShadow}
                disabled={buttonDisabled}>{type}</Button>
        {/each}
    </div>
</div>
