import { wrap } from "svelte-spa-router/wrap";
import Login from "./login.svelte";
import NotFound from "./not-found.svelte";

export const LINKS = {
    LOGIN: "/login",
    NOT_FOUND: "*",
};

export const ROUTES = {
    [LINKS.LOGIN]: wrap({
        component: Login,
        conditions: [],
    }),
    [LINKS.NOT_FOUND]: wrap({
        component: NotFound,
        conditions: [],
    }),
};
