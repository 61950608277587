<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import { DEFAULT_COMPONENT_SIZE, DEFAULT_COMPONENT_TYPE } from "../settings.const";

    export let type = DEFAULT_COMPONENT_TYPE;
    export let size = DEFAULT_COMPONENT_SIZE;
    export let checked = false;
    export let disabled = false;
    export let name = "";
    export let value = undefined;

    const dispatch = createEventDispatcher();

    function handleClick() {
        if (disabled) return;

        checked = !checked;
        dispatch("click", { checked, value });
    }
</script>

<div class="form-control {$$restProps.class || ''}">
    <label class="label cursor-pointer space-x-2">
        <input
            type="radio"
            {name}
            class="radio
            {`radio-${type}`}
            {`radio-${size}`}"
            {checked}
            {disabled}
            {value}
            on:click={handleClick} />
        <span class="label-text text-{size}"><slot /></span>
    </label>
</div>
