import { get, writable } from "svelte/store";
import type { Form, FormValue } from "./types";
import { extractControls } from "./utils";

export function initForm(initialControls = {}): Form {
    const extractedControls = extractControls(initialControls);
    const controls = writable(extractedControls);

    function value() {
        const value: FormValue = {};
        const controlValues = get(controls);

        for (const name in controlValues) {
            value[name] = controlValues[name].value;
        }

        return value;
    }

    return {
        controls,
        value,
    };
}
