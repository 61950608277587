<script lang="ts">
    import { BUTTON_STYLES } from "@svelte/design/actions/button.const";
    import Button from "@svelte/design/actions/button.svelte";
    import Card from "@svelte/design/display/card.svelte";
    import { COMPONENT_TYPES } from "@svelte/design/settings.const";
    import { loaded, loading } from "@svelte/store/common/loader";
    import { t } from "svelte-i18n";
    import LocaleSwitcher from "../common/locale-switcher.svelte";
    import ThemeSwitcher from "../common/theme-switcher.svelte";

    import { initForm } from "@svelte/forms";
    import Form from "@svelte/forms/form.svelte";

    const form = initForm({
        email: null,
        password: null,
        remember_me: false,
    });

    async function authenticate() {
        await loading();
        //const user = await login("bandraszyk@gmail.com", "Bandro123467");
        await loaded();
    }
</script>

<div class="w-96">
    <Card bordered={true} shadow={true}>
        <div slot="card-title" class="flex flex-row gap-2">
            <span>{$t("LOGIN_HEADER")}</span>
        </div>
        <div slot="card-content">
            <Form {form} type={COMPONENT_TYPES.NEUTRAL} />
        </div>
        <div slot="card-actions">
            <Button type={COMPONENT_TYPES.PRIMARY} style={BUTTON_STYLES.WIDE} on:click={authenticate}>
                {$t("LOGIN_BUTTON")}
            </Button>
        </div>
    </Card>
    <div class="w-96 flex flex-row justify-center gap-2 mt-4">
        <ThemeSwitcher />
        <LocaleSwitcher />
    </div>
</div>

<style></style>
