<script lang="ts">
    import Button from "@svelte/design/actions/button.svelte";
    import Dropdown from "@svelte/design/actions/dropdown.svelte";
    import Toggle from "@svelte/design/inputs/toggle.svelte";
    import Navbar from "@svelte/design/navigation/navbar.svelte";
    import { COMPONENT_TYPES, DEFAULT_BORDERED, DEFAULT_SHADOW } from "@svelte/design/settings.const";

    let navbarShadow = DEFAULT_SHADOW;
    let navbarBordered = DEFAULT_BORDERED;
    let navbarDisabled = false;
    let navbarType = COMPONENT_TYPES.PRIMARY;
    let navbarAllTypes = false;
    let isNavbarTypeOpen = false;

    function handleNavbarTypeChange(type) {
        navbarType = type;
        isNavbarTypeOpen = false;
    }
</script>

<div class="space-y-4">
    <h3>Navbar</h3>

    <div class="flex flex-col">
        <Toggle on:click={() => (navbarShadow = !navbarShadow)} checked={navbarShadow}>Shadow</Toggle>
        <Toggle on:click={() => (navbarBordered = !navbarBordered)} checked={navbarBordered}>Bordered</Toggle>
        <Toggle on:click={() => (navbarDisabled = !navbarDisabled)} checked={navbarDisabled}>Disabled</Toggle>
        <Toggle on:click={() => (navbarAllTypes = !navbarAllTypes)} checked={navbarAllTypes}>All Types</Toggle>

        <Dropdown bind:isOpen={isNavbarTypeOpen} disabled={navbarAllTypes}>
            <span slot="dropdown-summary">Selected Type: <strong>{navbarType}</strong></span>
            {#each Object.values(COMPONENT_TYPES) as type}
                <li>
                    <Button bordered={false} shadow={false} on:click={() => handleNavbarTypeChange(type)} type={COMPONENT_TYPES.GHOST}>{type}</Button>
                </li>
            {/each}
        </Dropdown>
    </div>

    <div class="flex flex-wrap gap-4">
        {#each navbarAllTypes ? Object.values(COMPONENT_TYPES) : [navbarType] as type}
            <Navbar {type} shadow={navbarShadow} bordered={navbarBordered} disabled={navbarDisabled}>
                <div slot="navbar-center">
                    <span>{type}</span>
                </div>
            </Navbar>
        {/each}
    </div>
</div>
