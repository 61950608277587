import { DEFAULT_THEME, THEMES } from "@constants/theme";
import { get } from "svelte/store";
import { localStorageWritable } from "../shared/local-storage";

export const currentTheme = localStorageWritable("theme", DEFAULT_THEME);

export const setTheme = (theme: string) => {
    if (THEMES.indexOf(theme) === -1) {
        theme = DEFAULT_THEME;
    }

    currentTheme.set(theme);
    return theme;
};

export const toggleTheme = () => {
    let current = get(currentTheme);

    if (THEMES.indexOf(current) === -1) {
        current = DEFAULT_THEME;
    }

    const next = THEMES[(THEMES.indexOf(current) + 1) % THEMES.length];
    currentTheme.set(next);
    return next;
};
