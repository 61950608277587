<script lang="ts">
    import Email from "@svelte/design/inputs/email.svelte";
    import Number from "@svelte/design/inputs/number.svelte";
    import Password from "@svelte/design/inputs/password.svelte";
    import Text from "@svelte/design/inputs/text.svelte";
    import Toggle from "@svelte/design/inputs/toggle.svelte";
    import { DEFAULT_COMPONENT_SIZE, DEFAULT_COMPONENT_TYPE } from "../design/settings.const";
    import { FormControlType, type Form } from "./types";

    export let form: Form;
    export let type = DEFAULT_COMPONENT_TYPE;
    export let size = DEFAULT_COMPONENT_SIZE;

    const { controls } = form;

    console.log($controls);
</script>

<form>
    {#each Object.entries($controls) as [name, control]}
        {#if control.type === FormControlType.TEXT}
            <Text {name} {type} {size} label={control.label} />
        {:else if control.type === FormControlType.PASSWORD}
            <Password {name} {type} {size} label={control.label} />
        {:else if control.type === FormControlType.EMAIL}
            <Email {name} {type} {size} label={control.label} />
        {:else if control.type === FormControlType.NUMBER}
            <Number {name} {type} {size} label={control.label} />
        {:else if control.type === FormControlType.CHECKBOX}
            <Toggle {name} {type} {size}>{control.label}</Toggle>
        {/if}
    {/each}
</form>

<style>
</style>
