<script lang="ts">
    import { LOCALES } from "@constants/locale";
    import Button from "@svelte/design/actions/button.svelte";
    import Dropdown from "@svelte/design/actions/dropdown.svelte";
    import { currentLocale, setLocale } from "@svelte/store/common/locale";
    import Cookies from "js-cookie";
    import { onMount } from "svelte";
    import { COMPONENT_TYPES } from "../../design/settings.const";

    let isDropdownOpen = false;

    function setInCookie(locale) {
        Cookies.set("locale", locale);
    }

    function onChange(locale) {
        setInCookie(locale);
        setLocale(locale);
        isDropdownOpen = false;
    }

    onMount(() => {
        setLocale($currentLocale);
    });
</script>

<Dropdown bind:isOpen={isDropdownOpen}>
    <span slot="dropdown-summary">
        {#each LOCALES as locale}
            {#if locale.code === $currentLocale}
                {locale.code}
            {/if}
        {/each}
    </span>

    {#each LOCALES as locale}
        <li>
            <Button on:click={() => onChange(locale.code)} type={COMPONENT_TYPES.GHOST} bordered={locale.code === $currentLocale} shadow={false}>
                {locale.code} / {locale.name}
            </Button>
        </li>
    {/each}
</Dropdown>
