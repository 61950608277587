<script lang="ts">
    import { onDestroy, onMount } from "svelte";
    import { DEFAULT_BORDERED, DEFAULT_COMPONENT_TYPE, DEFAULT_SHADOW } from "../settings.const";
    import { DROPDOWN_STYLES } from "./dropdown.const";

    export let isOpen = false;
    export let alignEnd = false;
    export let disabled = false;
    export let type = DEFAULT_COMPONENT_TYPE;
    export let style = DROPDOWN_STYLES.DEFAULT;
    export let bordered = DEFAULT_BORDERED;
    export let shadow = DEFAULT_SHADOW;

    let details;

    function handleOutsideClick(event) {
        if (!event.target.closest("details")) {
            isOpen = false;
        }
    }

    onMount(() => {
        document.addEventListener("click", handleOutsideClick);
    });

    onDestroy(() => {
        document.removeEventListener("click", handleOutsideClick);
    });
</script>

<details
    class="
        dropdown
        dropdown-{style}
        {disabled ? 'dropdown-disabled' : ''}
        {alignEnd ? 'dropdown-end' : ''}"
    bind:open={isOpen}
    bind:this={details}>
    <summary
        class="
            btn
            btn-{type} {disabled ? 'btn-disabled' : ''}
            {bordered ? 'btn-bordered' : ''}
            {shadow ? 'shadow' : ''}">
        <slot name="dropdown-summary" />
    </summary>
    <ul class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 z-10">
        <slot />
    </ul>
</details>
