import type { Writable } from "svelte/store";

export enum FormControlType {
    TEXT = "text",
    NUMBER = "number",
    PASSWORD = "password",
    EMAIL = "email",
    RADIO = "radio",
    CHECKBOX = "checkbox",
    TOGGLE = "toggle",
}
export type FormControlValue = string | number | boolean | null | undefined;
export interface FormControl {
    value: FormControlValue;
    type: FormControlType;
    label: string;
}
export interface FormValue {
    [key: string]: FormControlValue;
}
export interface FormControls {
    [key: string]: FormControl;
}
export interface Form {
    controls: Writable<FormControls>;
    value: () => FormValue;
}
