<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import { DEFAULT_COMPONENT_SIZE, DEFAULT_COMPONENT_TYPE } from "../settings.const";
    import { labelSize } from "./utils";

    export let type = DEFAULT_COMPONENT_TYPE;
    export let size = DEFAULT_COMPONENT_SIZE;
    export let disabled = false;
    export let bordered = true;
    export let shadow = true;
    export let label = "Label";
    export let name = "";
    export let placeholder = "";
    export let value = "";

    const dispatch = createEventDispatcher();

    function handleChange(event) {
        if (disabled) return;

        dispatch("change", { value: event.srcElement.value });
    }
</script>

<label class="form-control {$$restProps.class || ''}">
    <div class="label">
        <span class="label-text text-{labelSize(size)}">{label}</span>
    </div>
    <input
        type="email"
        {name}
        {placeholder}
        class="input
            input-{type}
            input-{size}
            {bordered ? 'input-bordered' : ''}
            {shadow ? 'shadow' : ''}"
        on:input={handleChange}
        {value}
        {disabled} />
</label>
