import { FormControlType, type FormControl, type FormControlValue } from "./types";

function toCapitalizedWords(name) {
    var words = name.match(/[A-Za-z][a-z]*/g) || [];

    return words.map(capitalize).join(" ");
}

function capitalize(word) {
    return word.charAt(0).toUpperCase() + word.substring(1);
}

function extractControl(control: FormControlValue | FormControl, name: string): FormControl {
    if (name === "password") {
        return {
            value: control as FormControlValue,
            type: FormControlType.PASSWORD,
            label: toCapitalizedWords(name),
        };
    }

    if (name === "email") {
        return {
            value: control as FormControlValue,
            type: FormControlType.EMAIL,
            label: toCapitalizedWords(name),
        };
    }
    console.log(control, typeof control, typeof control === "boolean");

    // '==' type of comparison done on purpose to filter out null and undefined values
    if (control != null) {
        if (typeof control === "number") {
            return {
                value: control as FormControlValue,
                type: FormControlType.TEXT,
                label: toCapitalizedWords(name),
            };
        }

        if (typeof control === "boolean") {
            return {
                value: control as FormControlValue,
                type: FormControlType.CHECKBOX,
                label: toCapitalizedWords(name),
            };
        }

        if (control instanceof Object) {
            return control as FormControl;
        }
    }

    return {
        value: control as FormControlValue,
        type: FormControlType.TEXT,
        label: toCapitalizedWords(name),
    };
}

export function extractControls(controls) {
    const extractedControls: { [key: string]: FormControl } = {};

    for (const name in controls) {
        extractedControls[name] = extractControl(controls[name], name);
    }

    return extractedControls;
}
