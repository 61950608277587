import { DEFAULT_LOCALE, LOCALES } from "@constants/locale";
import { locale } from "svelte-i18n";
import { get } from "svelte/store";
import { localStorageWritable } from "../shared/local-storage";

// eslint-disable-next-line no-magic-numbers
export const currentLocale = localStorageWritable(
    "locale",
    get(locale)?.substring(0, 2) ?? DEFAULT_LOCALE
);

export const setLocale = (code: string) => {
    if (LOCALES.findIndex(locale => locale.code === code) === -1) {
        code = DEFAULT_LOCALE;
    }

    currentLocale.set(code);
    locale.set(code);
    return code;
};

export const toggleLocale = () => {
    let current = get(currentLocale);

    if (LOCALES.indexOf(current) === -1) {
        current = DEFAULT_LOCALE;
    }

    const next = LOCALES[(LOCALES.indexOf(current) + 1) % LOCALES.length];
    currentLocale.set(next);
    return next;
};
