<script lang="ts">
    import { DEFAULT_COMPONENT_SIZE, DEFAULT_COMPONENT_TYPE } from "../settings.const";

    export let type = DEFAULT_COMPONENT_TYPE;
    export let size = DEFAULT_COMPONENT_SIZE;
    export let disabled = false;
</script>

<span
    class="
        material-symbols-rounded
        icon-{type}
        icon-{size}
        {disabled ? 'icon-disabled' : ''}
        {$$restProps.class || ''}
        ">
    <slot />
</span>

<style>
    .icon-default {
        color: oklch(var(--b));
    }

    .icon-primary {
        color: oklch(var(--p));
    }

    .icon-secondary {
        color: oklch(var(--s));
    }

    .icon-neutral {
        color: oklch(var(--n));
    }

    .icon-accent {
        color: oklch(var(--a));
    }

    .icon-success {
        color: oklch(var(--su));
    }

    .icon-info {
        color: oklch(var(--in));
    }

    .icon-warning {
        color: oklch(var(--wa));
    }

    .icon-error {
        color: oklch(var(--er));
    }

    .icon-disabled {
        color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
    }

    .icon-xs {
        font-size: 0.75rem;
    }

    .icon-sm {
        font-size: 1.25rem;
    }

    .icon-md {
        font-size: 1.5rem;
    }

    .icon-lg {
        font-size: 2rem;
    }
</style>
