<script>
    import { BUTTON_STYLES } from "@svelte/design/actions/button.const";
    import Button from "@svelte/design/actions/button.svelte";
    import Card from "@svelte/design/display/card.svelte";
    import Icon from "@svelte/design/display/icon.svelte";
    import Toggle from "@svelte/design/inputs/toggle.svelte";
    import Dropdown from "../../design/actions/dropdown.svelte";
    import {
        COMPONENT_SIZES,
        COMPONENT_TYPES,
        DEFAULT_BORDERED,
        DEFAULT_COMPONENT_SIZE,
        DEFAULT_COMPONENT_TYPE,
        DEFAULT_SHADOW,
    } from "../../design/settings.const";

    let cardShadow = DEFAULT_SHADOW;
    let cardBordered = DEFAULT_BORDERED;
    let cardDisabled = false;
    let cardAllTypes = false;
    let cardType = DEFAULT_COMPONENT_TYPE;
    let isCardTypeOpen = false;

    function handleCardTypeChange(type) {
        cardType = type;
        isCardTypeOpen = false;
    }

    let iconDisabled = false;
    let iconAllTypes = false;
    let iconType = DEFAULT_COMPONENT_TYPE;
    let iconSize = DEFAULT_COMPONENT_SIZE;
    let isIconTypeOpen = false;
    let isIconSizeOpen = false;

    function handleIconTypeChange(type) {
        iconType = type;
        isIconTypeOpen = false;
    }

    function handleIconSizeChange(size) {
        iconSize = size;
        isIconSizeOpen = false;
    }
</script>

<div class="space-y-4">
    <h3>Card</h3>

    <div class="flex flex-col">
        <Toggle on:click={() => (cardShadow = !cardShadow)} checked={cardShadow}>Shadow</Toggle>
        <Toggle on:click={() => (cardBordered = !cardBordered)} checked={cardBordered}>Bordered</Toggle>
        <Toggle on:click={() => (cardDisabled = !cardDisabled)} checked={cardDisabled}>Disabled</Toggle>
        <Toggle on:click={() => (cardAllTypes = !cardAllTypes)} checked={cardDisabled}>All Types</Toggle>

        <Dropdown bind:isOpen={isCardTypeOpen} disabled={cardAllTypes}>
            <span slot="dropdown-summary">Selected Type: <strong>{cardType}</strong></span>
            {#each Object.values(COMPONENT_TYPES) as type}
                <li>
                    <Button bordered={false} shadow={false} on:click={() => handleCardTypeChange(type)} type={COMPONENT_TYPES.GHOST}>{type}</Button>
                </li>
            {/each}
        </Dropdown>
    </div>

    <div class="flex flex-wrap gap-4">
        {#each cardAllTypes ? Object.values(COMPONENT_TYPES) : [cardType] as type}
            <Card {type} bordered={cardBordered} shadow={cardShadow} disabled={cardDisabled}>
                <div slot="card-title" class="flex flex-row items-center gap-2">
                    <span>{type}</span>
                </div>
                <div slot="card-content">
                    <p>Card content</p>
                </div>
                <div slot="card-actions">
                    <Button {type} style={BUTTON_STYLES.WIDE} bordered={cardBordered} shadow={cardShadow} disabled={cardDisabled}>cardType</Button>
                </div>
            </Card>
        {/each}
    </div>

    <h3>Icon</h3>

    <div class="flex flex-col">
        <Toggle on:click={() => (iconDisabled = !iconDisabled)} checked={iconDisabled}>Disabled</Toggle>
        <Toggle on:click={() => (iconAllTypes = !iconAllTypes)} checked={iconAllTypes}>All Types</Toggle>
        <Dropdown bind:isOpen={isIconTypeOpen} disabled={iconAllTypes}>
            <span slot="dropdown-summary">Selected Type: <strong>{iconType}</strong></span>
            {#each Object.values(COMPONENT_TYPES) as type}
                <li>
                    <Button bordered={false} shadow={false} on:click={() => handleIconTypeChange(type)} type={COMPONENT_TYPES.GHOST}>{type}</Button>
                </li>
            {/each}
        </Dropdown>
        <Dropdown bind:isOpen={isIconSizeOpen}>
            <span slot="dropdown-summary">Selected Size: <strong>{iconSize}</strong></span>
            {#each Object.values(COMPONENT_SIZES) as size}
                <li>
                    <Button bordered={false} shadow={false} on:click={() => handleIconSizeChange(size)} type={COMPONENT_TYPES.GHOST}>{size}</Button>
                </li>
            {/each}
        </Dropdown>
    </div>

    <div class="flex flex-wrap gap-4">
        {#each iconAllTypes ? Object.values(COMPONENT_TYPES) : [iconType] as type}
            <Icon {type} disabled={iconDisabled} size={iconSize}>home</Icon>
        {/each}
    </div>
</div>
