import Components from "./index.svelte";

import { wrap } from "svelte-spa-router/wrap";

export const COMPONENTS_LINKS = {
    INDEX: "/components",
};

export const COMPONENTS_ROUTES = {
    [COMPONENTS_LINKS.INDEX]: wrap({
        component: Components,
        conditions: [],
    }),
};
