export const COMPONENT_TYPES = {
    NONE: "none",
    NEUTRAL: "neutral",
    PRIMARY: "primary",
    SECONDARY: "secondary",
    ACCENT: "accent",
    INFO: "info",
    SUCCESS: "success",
    WARNING: "warning",
    ERROR: "error",
    GHOST: "ghost",
    GLASS: "glass",
};

export const COMPONENT_SIZES = {
    LARGE: "lg",
    MEDIUM: "md",
    SMALL: "sm",
    EXTRA_SMALL: "xs",
};

export const DEFAULT_COMPONENT_SIZE = COMPONENT_SIZES.MEDIUM;
export const DEFAULT_COMPONENT_TYPE = COMPONENT_TYPES.NONE;
export const DEFAULT_BORDERED: boolean = true;
export const DEFAULT_SHADOW: boolean = true;
