<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import Icon from "../display/icon.svelte";
    import { DEFAULT_BORDERED, DEFAULT_COMPONENT_SIZE, DEFAULT_COMPONENT_TYPE, DEFAULT_SHADOW } from "../settings.const";
    import { BUTTON_STYLES } from "./button.const";
    import Button from "./button.svelte";

    export let type = DEFAULT_COMPONENT_TYPE;
    export let size = DEFAULT_COMPONENT_SIZE;
    export let checked = false;
    export let disabled = false;
    export let bordered = DEFAULT_BORDERED;
    export let shadow = DEFAULT_SHADOW;

    const dispatch = createEventDispatcher();

    function handleChange(checked) {
        if (disabled) return;
        checked = !checked;
        dispatch("change", { checked });
    }
</script>

<Button {type} {size} {bordered} {shadow} style={BUTTON_STYLES.SQUARE}>
    <label class="swap">
        <input type="checkbox" on:change={handleChange} {checked} {disabled} />
        <slot name="swap-off">
            <Icon slot="off" class="swap-off">toggle_off</Icon>
        </slot>
        <slot name="swap-on">
            <Icon slot="on" class="swap-on">toggle_on</Icon>
        </slot>
    </label>
</Button>
