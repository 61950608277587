<script>
    import Button from "@svelte/design/actions/button.svelte";
    import Dropdown from "@svelte/design/actions/dropdown.svelte";
    import Checkbox from "@svelte/design/inputs/checkbox.svelte";
    import Radio from "@svelte/design/inputs/radio.svelte";
    import Text from "@svelte/design/inputs/text.svelte";
    import Toggle from "@svelte/design/inputs/toggle.svelte";
    import { COMPONENT_SIZES, COMPONENT_TYPES, DEFAULT_BORDERED, DEFAULT_COMPONENT_SIZE, DEFAULT_COMPONENT_TYPE } from "@svelte/design/settings.const";

    function handleToggleClick(type, checked) {
        console.log(checked);
        console.log(`${type} toggle clicked - ${checked}`);
    }

    function handleRadioClick(type, checked, value) {
        console.log(`${type} checkbox clicked - ${checked} ${value}`);
    }

    function handleTextChange(type, value) {
        console.log(`${type} text changed - ${value}`);
    }

    let checkboxDisabled = false;
    let checkboxChecked = false;
    let checkboxSize = DEFAULT_COMPONENT_SIZE;
    let checkboxType = DEFAULT_COMPONENT_TYPE;
    let checkboxAllTypes = false;
    let isCheckboxTypeOpen = false;
    let isCheckboxSizeOpen = false;

    function handleCheckboxTypeChange(type) {
        checkboxType = type;
        isCheckboxTypeOpen = false;
    }

    function handleCheckboxSizeChange(size) {
        checkboxSize = size;
        isCheckboxSizeOpen = false;
    }

    let toggleDisabled = false;
    let toggleChecked = false;
    let toggleSize = DEFAULT_COMPONENT_SIZE;
    let toggleType = DEFAULT_COMPONENT_TYPE;
    let toggleAllTypes = false;
    let isToggleTypeOpen = false;
    let isToggleSizeOpen = false;

    function handleToggleTypeChange(type) {
        toggleType = type;
        isToggleTypeOpen = false;
    }

    function handleToggleSizeChange(size) {
        toggleSize = size;
        isToggleSizeOpen = false;
    }

    let radioDisabled = false;
    let radioValue = DEFAULT_COMPONENT_TYPE;
    let radioSize = DEFAULT_COMPONENT_SIZE;
    let radioType = DEFAULT_COMPONENT_TYPE;
    let radioAllTypes = false;
    let isRadioTypeOpen = false;
    let isRadioSizeOpen = false;
    let radioName = "component_type";

    function handleRadioTypeChange(type) {
        radioType = type;
        isRadioTypeOpen = false;
    }

    function handleRadioSizeChange(size) {
        radioSize = size;
        isRadioSizeOpen = false;
    }

    let textBordered = DEFAULT_BORDERED;
    let textDisabled = false;
    let textValue = "";
    let textSize = DEFAULT_COMPONENT_SIZE;
    let textType = DEFAULT_COMPONENT_TYPE;
    let textAllTypes = false;
    let isTextTypeOpen = false;
    let isTextSizeOpen = false;

    function handleTextTypeChange(type) {
        textType = type;
        isTextTypeOpen = false;
    }

    function handleTextSizeChange(size) {
        textSize = size;
        isTextSizeOpen = false;
    }
</script>

<div class="space-y-4">
    <h3>Checkbox</h3>

    <div class="flex flex-col">
        <Toggle on:click={() => (checkboxDisabled = !checkboxDisabled)} checked={checkboxDisabled}>Disabled</Toggle>
        <Toggle on:click={() => (checkboxAllTypes = !checkboxAllTypes)} checked={checkboxAllTypes}>All Types</Toggle>

        <Dropdown bind:isOpen={isCheckboxTypeOpen} disabled={checkboxAllTypes}>
            <span slot="dropdown-summary">Selected Type: <strong>{checkboxType}</strong></span>
            {#each Object.values(COMPONENT_TYPES) as type}
                <li>
                    <Button bordered={false} shadow={false} on:click={() => handleCheckboxTypeChange(type)} type={COMPONENT_TYPES.GHOST}>{type}</Button>
                </li>
            {/each}
        </Dropdown>

        <Dropdown bind:isOpen={isCheckboxSizeOpen}>
            <span slot="dropdown-summary">Selected Size: <strong>{checkboxSize}</strong></span>
            {#each Object.values(COMPONENT_SIZES) as size}
                <li>
                    <Button bordered={false} shadow={false} on:click={() => handleCheckboxSizeChange(size)} type={COMPONENT_TYPES.GHOST}>{size}</Button>
                </li>
            {/each}
        </Dropdown>
    </div>

    <div class="flex flex-wrap gap-4">
        {#each checkboxAllTypes ? Object.values(COMPONENT_TYPES) : [checkboxType] as type}
            <Checkbox
                {type}
                on:click={event => (checkboxChecked = event.detail.checked)}
                disabled={checkboxDisabled}
                checked={checkboxChecked}
                size={checkboxSize}>{type}</Checkbox>
        {/each}
    </div>

    <h3>Toggle</h3>

    <div class="flex flex-col">
        <Toggle on:click={() => (toggleDisabled = !toggleDisabled)} checked={toggleDisabled}>Disabled</Toggle>
        <Toggle on:click={() => (toggleAllTypes = !toggleAllTypes)} checked={toggleAllTypes}>All Types</Toggle>

        <Dropdown bind:isOpen={isToggleTypeOpen} disabled={toggleAllTypes}>
            <span slot="dropdown-summary">Selected Type: <strong>{toggleType}</strong></span>
            {#each Object.values(COMPONENT_TYPES) as type}
                <li>
                    <Button bordered={false} shadow={false} on:click={() => handleToggleTypeChange(type)} type={COMPONENT_TYPES.GHOST}>{type}</Button>
                </li>
            {/each}
        </Dropdown>

        <Dropdown bind:isOpen={isToggleSizeOpen}>
            <span slot="dropdown-summary">Selected Size: <strong>{toggleSize}</strong></span>
            {#each Object.values(COMPONENT_SIZES) as size}
                <li>
                    <Button bordered={false} shadow={false} on:click={() => handleToggleSizeChange(size)} type={COMPONENT_TYPES.GHOST}>{size}</Button>
                </li>
            {/each}
        </Dropdown>
    </div>

    <div class="flex flex-wrap gap-4">
        {#each toggleAllTypes ? Object.values(COMPONENT_TYPES) : [toggleType] as type}
            <Toggle {type} on:click={event => (toggleChecked = event.detail.checked)} disabled={toggleDisabled} checked={toggleChecked} size={toggleSize}
                >{type}</Toggle>
        {/each}
    </div>

    <h3>Radio</h3>

    <div class="flex flex-col">
        <Toggle on:click={() => (radioDisabled = !radioDisabled)} checked={radioDisabled}>Disabled</Toggle>
        <Toggle on:click={() => (radioAllTypes = !radioAllTypes)} checked={radioAllTypes}>All Types</Toggle>

        <Dropdown bind:isOpen={isRadioTypeOpen} disabled={radioAllTypes}>
            <span slot="dropdown-summary">Selected Type: <strong>{radioType}</strong></span>
            {#each Object.values(COMPONENT_TYPES) as type}
                <li>
                    <Button bordered={false} shadow={false} on:click={() => handleRadioTypeChange(type)} type={COMPONENT_TYPES.GHOST}>{type}</Button>
                </li>
            {/each}
        </Dropdown>

        <Dropdown bind:isOpen={isRadioSizeOpen}>
            <span slot="dropdown-summary">Selected Size: <strong>{radioSize}</strong></span>
            {#each Object.values(COMPONENT_SIZES) as size}
                <li>
                    <Button bordered={false} shadow={false} on:click={() => handleRadioSizeChange(size)} type={COMPONENT_TYPES.GHOST}>{size}</Button>
                </li>
            {/each}
        </Dropdown>
    </div>

    <div class="flex flex-wrap gap-4">
        {#each radioAllTypes ? Object.values(COMPONENT_TYPES) : [radioType] as type}
            <Radio
                {type}
                name={radioName}
                on:click={event => (radioValue = event.detail.value)}
                disabled={radioDisabled}
                value={type}
                checked={type === radioValue}
                size={radioSize}>{type}</Radio>
        {/each}
    </div>

    <h3>Text</h3>

    <div class="flex flex-col">
        <Toggle on:click={() => (textBordered = !textBordered)} checked={textBordered}>Bordered</Toggle>
        <Toggle on:click={() => (textDisabled = !textDisabled)} checked={textDisabled}>Disabled</Toggle>
        <Toggle on:click={() => (textAllTypes = !textAllTypes)} checked={textAllTypes}>All Types</Toggle>

        <Dropdown bind:isOpen={isTextTypeOpen} disabled={textAllTypes}>
            <span slot="dropdown-summary">Selected Type: <strong>{textType}</strong></span>
            {#each Object.values(COMPONENT_TYPES) as type}
                <li>
                    <Button bordered={false} shadow={false} on:click={() => handleTextTypeChange(type)} type={COMPONENT_TYPES.GHOST}>{type}</Button>
                </li>
            {/each}
        </Dropdown>

        <Dropdown bind:isOpen={isTextSizeOpen}>
            <span slot="dropdown-summary">Selected Size: <strong>{textSize}</strong></span>
            {#each Object.values(COMPONENT_SIZES) as size}
                <li>
                    <Button bordered={false} shadow={false} on:click={() => handleTextSizeChange(size)} type={COMPONENT_TYPES.GHOST}>{size}</Button>
                </li>
            {/each}
        </Dropdown>
    </div>

    <div class="flex flex-wrap gap-4">
        {#each textAllTypes ? Object.values(COMPONENT_TYPES) : [textType] as type}
            <Text
                {type}
                size={textSize}
                on:change={event => (textValue = event.detail.value)}
                value={textValue}
                placeholder={`Enter ${type}...`}
                disabled={textDisabled}
                bordered={textBordered}
                label={`Input for ${type}`}>
            </Text>
        {/each}
    </div>
</div>
