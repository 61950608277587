<script>
    import { createEventDispatcher } from "svelte";
    import { DEFAULT_COMPONENT_SIZE, DEFAULT_COMPONENT_TYPE } from "../settings.const";

    export let type = DEFAULT_COMPONENT_TYPE;
    export let size = DEFAULT_COMPONENT_SIZE;
    export let checked = false;
    export let disabled = false;
    export let name = "";

    const dispatch = createEventDispatcher();

    function handleClick() {
        if (disabled) return;
        checked = !checked;
        dispatch("click", { checked });
    }
</script>

<div class="form-control {$$restProps.class || ''}">
    <label class="cursor-pointer label space-x-2 justify-start">
        <input
            type="checkbox"
            {checked}
            class="toggle
            {`toggle-${type}`}
            {`toggle-${size}`}"
            on:click={handleClick}
            {name}
            {disabled} />
        <span class="label-text text-{size}"><slot /></span>
    </label>
</div>
