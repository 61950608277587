<script lang="ts">
    import { DEFAULT_BORDERED, DEFAULT_COMPONENT_TYPE, DEFAULT_SHADOW } from "../settings.const";

    export let type = DEFAULT_COMPONENT_TYPE;
    export let disabled = false;
    export let shadow = DEFAULT_SHADOW;
    export let bordered = DEFAULT_BORDERED;
</script>

<div
    class="
        rounded-box
        navbar
        navbar-{type}
        {disabled ? 'navbar-disabled' : ''}
        {shadow ? 'shadow' : ''}
        {bordered ? 'navbar-bordered' : ''}
        {$$restProps.class || ''}">
    <div class="navbar-start">
        <slot name="navbar-start" />
    </div>
    <div class="navbar-center">
        <slot name="navbar-center" />
    </div>
    <div class="navbar-end">
        <slot name="navbar-end" />
    </div>
</div>

<style>
    .navbar-bordered {
        border-width: 1px;
        --tw-border-opacity: 1;
        border-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-border-opacity)));
    }

    .navbar-default {
        background-color: hsl(var(--background-100) / 0.1);
        color: oklch(var(--bc));
    }

    .navbar-primary {
        background-color: oklch(var(--p));
        color: oklch(var(--pc));
    }

    .navbar-secondary {
        background-color: oklch(var(--s));
        color: oklch(var(--sc));
    }

    .navbar-neutral {
        background-color: oklch(var(--n));
        color: oklch(var(--nc));
    }

    .navbar-accent {
        background-color: oklch(var(--a));
        color: oklch(var(--ac));
    }

    .navbar-success {
        background-color: oklch(var(--su));
        color: oklch(var(--suc));
    }

    .navbar-info {
        background-color: oklch(var(--in));
        color: oklch(var(--inc));
    }

    .navbar-warning {
        background-color: oklch(var(--wa));
        color: oklch(var(--wac));
    }

    .navbar-error {
        background-color: oklch(var(--er));
        color: oklch(var(--erc));
    }

    .navbar-disabled {
        background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
        border-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-border-opacity)));
        color: var(--fallback-bc, oklch(var(--bc) / 0.4));
    }
</style>
